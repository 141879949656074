<template>
  <v-container>
    <v-card class="ma-3">
      <base-loading v-if="loading.loading" />
      <div v-else class="content">
        <template>
          <v-card
            :class="{ 'card-success': register, 'card-warn': !register }"
            style="min-height: 60vh"
          >
            <h4 v-if="!register" slot="header" class="card-title text-center">
              Edição de projeto
            </h4>
            <h4 v-else slot="header" class="card-title text-center">
              Cadastro de projeto
            </h4>
            <div class="clearfix" />
            <div>
              <v-form
                id="form"
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                class="pa-3"
                lazy-validation
                @submit.prevent="createOrUpdate"
              >
                <v-card>
                  <v-list-item-content>
                    <v-card-title class="headline mx-auto">
                      Dados do projeto
                    </v-card-title>
                    <v-row v-if="!loading.loading" class="pa-3">
                      <v-col cols="6">
                        <v-text-field
                          v-model="agroindustry.name"
                          label="Agroindústria *"
                          :rules="rules.requiredRules"
                          :disabled="true"
                          required
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form.name"
                          label="Nome do projeto *"
                          :rules="rules.requiredRules"
                          required
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="form.fazendas"
                          label="Fazendas *"
                          name="fazendas"
                          :items="list.fazendas"
                          :loading="loading.fazendas"
                          :disabled="loading.fazendas"
                          item-text="pessoajuridica.NomeFantasia"
                          item-value="CodFazenda"
                          clearable
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 5" small class="caption">
                              <span>
                                {{ item.pessoajuridica.NomeFantasia }}
                              </span>
                            </v-chip>
                            <span v-if="index === 5" class="grey--text caption">
                              (+{{ form.fazendas.length - 5 }} outros)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuFarmsStart"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.startFarms
                                  ? moment(form.startFarms).format('DD/MM/YYYY')
                                  : ''
                              "
                              label="Data de início das fazendas *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :disabled="register"
                              :rules="rules.requiredRules"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.startFarms"
                            style="margin: 0px"
                            color="teal"
                            locale="pt-br"
                            @input="date.menuFarmsStart = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="form.reportType"
                          :items="list.reportsType"
                          label="Relatório *"
                          item-text="name"
                          item-value="id"
                          hint="Selecione um relatório"
                          required
                          :rules="rules.requiredRules"
                          :loading="loading.reportsType"
                          :disabled="loading.reportsType"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="form.status"
                          :items="Object.entries(ProjectStatusText)"
                          label="Status *"
                          item-text="1"
                          item-value="0"
                          hint="Selecine o status da visita"
                          required
                        >
                          <template v-slot:selection="data">
                            <v-list-item-avatar size="18">
                              <v-avatar
                                left
                                :color="ProjectStatusClassColors[data.item[0]]"
                                size="16"
                                style="border: 1px solid black !important"
                              ></v-avatar>
                            </v-list-item-avatar>
                            <span class="ml-2">
                              {{ data.item[1] }}
                            </span>
                          </template>

                          <template v-slot:item="data">
                            <v-list-item-avatar size="16">
                              <v-avatar
                                left
                                :color="ProjectStatusClassColors[data.item[0]]"
                                size="16"
                                style="border: 1px solid black !important"
                              ></v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                data.item[1]
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuDataIni"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.startProject
                                  ? moment(form.startProject).format(
                                      'DD/MM/YYYY',
                                    )
                                  : ''
                              "
                              label="Data Inicial *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :disabled="register"
                              :rules="requiredStartTime"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.startProject"
                            style="margin: 0px"
                            color="teal"
                            locale="pt-br"
                            @input="date.menuDataIni = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuDataFim"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.endProject
                                  ? moment(form.endProject).format('DD/MM/YYYY')
                                  : ''
                              "
                              label="Data final *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :rules="requiredEndDateTime"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.endProject"
                            style="margin: 0px"
                            color="teal"
                            locale="pt-br"
                            @input="date.menuDataFim = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuDataProtocol"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.dateProtocol
                                  ? moment(form.dateProtocol).format(
                                      'DD/MM/YYYY',
                                    )
                                  : ''
                              "
                              label="Protocolo na Receita *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :rules="requiredEndDateTime"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.dateProtocol"
                            style="margin: 0px"
                            color="teal"
                            locale="pt-br"
                            @input="date.menuDataProtocol = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuDataProtocolMapa"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.dateProtocolMapa
                                  ? moment(form.dateProtocolMapa).format(
                                      'DD/MM/YYYY',
                                    )
                                  : ''
                              "
                              label="Data do protocolo MAPA *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :rules="requiredMapa"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.dateProtocolMapa"
                            style="margin: 0px"
                            color="teal"
                            required-mapa
                            locale="pt-br"
                            @input="date.menuDataProtocolMapa = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="date.menuDataPublication"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="
                                form.datePublication
                                  ? moment(form.datePublication).format(
                                      'DD/MM/YYYY',
                                    )
                                  : ''
                              "
                              label="Data Publicação *"
                              prepend-icon="mdi-calendar"
                              color="teal"
                              readonly
                              :rules="requiredEndDateTime"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.datePublication"
                            style="margin: 0px"
                            color="teal"
                            locale="pt-br"
                            @input="date.menuDataPublication = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-card>
                <v-btn
                  type="submit"
                  :disabled="loading.save"
                  :loading="loading.save"
                  :color="!$route.query.id ? 'success' : 'warning'"
                  form="form"
                >
                  {{ !$route.query.id ? 'Salvar' : 'Atualizar' }}
                </v-btn>

                <v-btn
                  :disabled="loading.save"
                  color="error"
                  class="ml-3"
                  @click="cancel"
                >
                  Cancelar
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </template>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import farmsService from '../../services/api/farms'
import projectService from '../../services/api/project'
import reportTypeService from '../../services/api/reportType'
import {
  ProjectStatus,
  ProjectStatusClassColors,
  ProjectStatusText,
} from '../../utils/enums/ProjectStatus'
export default {
  name: 'ProjectForm',
  components: {},
  data() {
    return {
      ProjectStatus,
      ProjectStatusClassColors,
      ProjectStatusText,
      loading: {
        loading: false,
        save: false,
        fazendas: false,
        reportsType: false,
      },

      form: {
        name: '',
        agroindustry: '',
        reportType: '',
        startProject: null,
        endProject: null,
        dateProtocol: null,
        dateProtocolMapa: null,
        datePublication: null,
        status: 0,
        fazendas: [],
        startFarms: null,
      },

      rules: {
        requiredRules: [v => !!v || '* Obrigatório'],
      },

      list: {
        fazendas: [],
        reportsType: [],
      },

      agroindustry: null,

      date: {
        menuDataIni: false,
        menuDataFim: false,
        menuDataProtocol: false,
        menuDataProtocolMapa: false,
        menuDataPublication: false,
        menuFarmsStart: false,
      },

      requiredEndDateTime: [
        v =>
          this.validateEndTime(v) ||
          '* Esta data e hora não pode ser menor que a inicial',
        v => !!v || '* Obrigatório',
      ],

      requiredStartTime: [
        v => !!v || '* Obrigatório',
        v => this.validateStartTime(v) || '* Corrija a data deste campo',
      ],

      requiredMapa: [
        v => !!v || '* Obrigatório',
        v =>
          this.validateMapa(v) || '* A data não pode ser maior que a inicial',
      ],

      valid: false,
      register: false,
    }
  },

  mounted() {},

  async created() {
    this.register = !!this.$route.query.id
    this.agroindustry = {
      ...this.$route.query,
    }

    await this.init()
  },

  methods: {
    validateMapa(v) {
      const mapaDate = new Date(moment(v, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      const startDate = new Date(
        moment(this.form.startProject).format('YYYY-MM-DD'),
      )

      if (mapaDate > startDate) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data do protocolo MAPA não pode ser maior que a inicial',
        })

        return false
      }

      return true
    },

    validateStartTime(v) {
      const endTime = moment(this.form.endProject)
      const startTime = moment(v, 'DD/MM/YYYY')

      if (
        (endTime && startTime.isAfter(endTime)) ||
        startTime.isSame(endTime)
      ) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora inicial não pode ser maior que a final',
        })

        return false
      }

      const publication = moment(this.form.datePublication)

      if (
        (publication && startTime.isAfter(publication)) ||
        startTime.isSame(publication)
      ) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora inicial não pode ser maior que a da publicação',
        })

        return false
      }

      return true
    },

    validateEndTime(v) {
      const startTime = new Date(this.form.startProject)
      const endTime = new Date(
        moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      )

      if (startTime > endTime) {
        this.Toast().fire({
          icon: 'error',
          title: 'Esta data e hora não pode ser menor que a inicial',
        })

        return false
      }

      return true
    },
    async init() {
      if (this.$route.query.id) {
        await this.find()
      }
      this.loadFarms()
      this.getReportsType()
    },

    async find() {
      try {
        this.loading.loading = true
        const { data } = await projectService.show(this.$route.query.id)

        this.agroindustry = {
          codAgroindustria: data.agroindustria.CodAgroindustria,
          name: data.agroindustria.pessoajuridica.NomeFantasia,
        }

        this.form = {
          ...data,
          fazendas: data.fazendas_project.map(f => f.fazendaId),
          reportType: Number(data.reportType),
          status: `${data.status}`,
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar projeto: ' + error.message,
        })
      } finally {
        this.loading.loading = false
      }
    },

    async createOrUpdate() {
      const validate = this.$refs.form.validate()

      this.loading.save = true

      try {
        if (!validate) {
          return this.Toast().fire({
            icon: 'error',
            title: 'Preencha os itens obrigatórios sinalizados com ("*").',
          })
        }

        let result = {}

        if (this.$route.query.id) {
          result = await this.update()
        } else {
          result = await this.create()
        }

        if (!result.success) {
          throw new Error(result.message)
        }

        this.Toast().fire({
          icon: 'success',
          title: result.message,
        })

        this.$router.go(-1)
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao salvar projeto: ' + error.message,
        })
      } finally {
        this.loading.save = false
      }
    },

    cancel() {
      this.$router.go(-1)
    },

    async create() {
      this.form.agroindustry = this.agroindustry.agroindustry
      const result = await projectService.create(this.form)

      return result
    },

    async update() {
      const result = await projectService.patch({
        id: this.$route.query.id,
        form: this.form,
      })

      return result
    },

    async loadFarms() {
      this.loading.fazendas = true
      const data = await farmsService.list()
      this.list.fazendas = data
      this.loading.fazendas = false
    },

    async getReportsType() {
      this.loading.reportsType = true
      const result = await reportTypeService.list()

      this.list.reportsType = result
      this.loading.reportsType = false
    },
  },
}
</script>
