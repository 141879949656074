import api from '../../../api/api'

export default {
  create: async form => {
    const result = api.save.createProjectPlanning(form)

    return result
  },

  patch: async ({ id, form }) => {
    const result = api.patch.projectPlanning(id, form)

    return result
  },

  index: async ({ agroindustry = null }) => {
    const result = api.get.projectsPlanning({ agroindustry })

    return result
  },

  show: async id => {
    const result = api.get.projectPlanning(id)

    return result
  },

  searchByUser: async id => {
    const result = api.get.searchSchedules(id)

    return result
  },

  delete: async id => {
    const result = api.delete.projectPlanning(id)

    return result
  },
}
